import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from 'styled-components'

const BlogLink = styled(Link)`
text-decoration: none;
`

const BlogTitle = styled.h3`
margin:bottom:20px;
color:blue;
`

export default ({data}) => (
  <Layout>
    <SEO title="Home" />
    <div>
      <h2>Talks & Apperances</h2>
      <p>I love speaking at technical events, taking up workshops and sharing my knowledge
        around things that I know. Through Public Speaking, I have improved my communication
        skills and acquired a skill of sharing personal and informative talks that can impact
        Students and Professionals alike. This page serves as my Public Speaking portfolio where 
        you can find all my past talks & apperances. You can reach out to me 
        at <a href="contact@harshcasper.com">contact@harshcasper.com</a>.
      </p>
      <h4> { data.allMarkdownRemark.totalCount } talks yet. </h4>
      {
        data.allMarkdownRemark.edges.map(({node})=> (
          <div key={node.id}> 
          <BlogLink to={ node.fields.slug }>
          <BlogTitle> { node.frontmatter.title } - { node.frontmatter.description } </BlogTitle>
          </BlogLink>
          <p> {node.excerpt} </p>
          </div>
        ) )

      }
    </div>
  </Layout>
)


export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            date
            author
            description
            title
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }

`